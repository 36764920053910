.checkout-shipping-method .order-attributes {
    margin-bottom: $indent__base;
    margin-top: $checkout-shipping-address__margin-top;

    @include min-screen($screen__m) {
        max-width: $checkout-shipping-address__max-width;
    }

    .field .label {
        padding-top: 0;
        vertical-align: middle;
    }
}
