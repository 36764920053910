.checkout-agreements-block {
    display: inline-block;
    vertical-align: text-bottom;

    @include min-screen($screen__m) {
        width: calc(100% - 160px - 4px);
    }

    .checkout-agreement.choice {
        display: flex;
        align-items: center;

        &:before {
            content: none;
        }

        .action-show {
            text-align: left;
        }

        > input,
        > label.label:after {
            margin-top: 0;
            vertical-align: middle;
        }

        > label.label {
            padding-right: $indent__s;

            &,
            &:after {
                margin-bottom: 0;
                margin-left: $indent__xs - 1px;
            }

            &:after {
                position: static;
                top: 0;
            }
        }
    }
}

.checkout-payment-method {
    .payment-methods {
        @include max-screen($screen__m) {
            margin: 0;
        }
    }

    .step-title {
        @include max-screen($screen__m) {
            padding-left: 0.75 * $indent__base;
        }
    }
}

.column form .payment-method-content .actions-toolbar {
    @include min-screen($screen__m) {
        display: inline-block;
        margin-left: 0;
    }
}
