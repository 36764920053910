.am-related-box {
    .action.towishlist {
        display: none;
    }

    .owl-carousel .owl-nav {
        margin-top: 0;
    }

    // Center align product labels within the crossells section of the popup
    .block.related .amlabel-position-wrapper {
        left: 50%;
        transform: translateX(-50%);
    }

    .block.related .product-item-details {
        // Owl carousel is disabled at viewports >= 769px
        @include min-screen(769px) {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }

    .block.related .products .product-item {
        width: 50%;

        // Owl carousel is disabled at viewports >= 769px
        @include min-screen(769px) {
            width: 100%;
        }
    }

    .am-slider {
        margin: 5px 0 !important; // Overides Amasty Styles
    }

    .products .product-item-actions {
        .tocart.primary {
            background: $button-primary__background;
        }
    }

    .price-box .price-container {
        .price-excluding-tax .price {
            font-size: $font-size__l;
            font-weight: $font-weight__bold;
        }

        .price-including-tax .price {
            font-size: $font-size__base;
            font-weight: $font-weight__regular;
        }
    }

    .owl-dots {
        height: $indent__base;
    }

    .am-slider.owl-carousel .owl-nav {
        top: calc(50% - #{$indent__base});
    }
}

.amcart-subtotal .price {
    &:after {
        content: ' exc VAT';
    }
}

.am-photo-container .amcart-qty-wrap {
    margin-left: 17px // Increased by 10px
}

// Id used to overide style defined in Amasty_Cart/css/source/mkcss/amcart.css
#confirmBox .products.products-grid .product-item-actions {
    // Owl carousel is disabled at viewports >= 769px
    @include min-screen(769px) {
        margin-top: auto;
    }
}
