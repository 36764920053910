.page-footer {
    @include lib-css(color, $color-alabaster);

    .footer-links {
        @include lib-css(padding, $indent__m 0);

        .block.newsletter {
            margin: 0;
            width: 100%;

            .title {
                display: block;

                strong {
                    @include lib-css(font-size, $font-size__l);

                    font-weight: 400;
                    text-transform: uppercase;
                }
            }

            .content {
                .control {
                    &:before {
                        content: '';
                    }

                    #newsletter {
                        @include lib-css(color, $primary__color);
                        @include lib-css(height, $footer-subscribe-input-height);
                        @include lib-css(padding-left,  $indent__xs);

                        opacity: 0.8;
                    }
                }

                .actions {
                    .action.subscribe {
                        @include lib-css(background-color, $secondary__color);
                        @include lib-css(border, $border-width solid $color-alabaster);
                        @include lib-css(height, $footer-subscribe-input-height);
                        @include lib-css(padding, 0 $indent__m);

                        text-transform: uppercase;

                        &:hover {
                            @include lib-css(background-color, $primary__color);
                        }
                    }
                }
            }
        }
    }

    .footer-additional {
        @include lib-css(padding-bottom, $indent__m);

        text-align: center;

        .footer-icons {
            @include lib-css(padding-bottom, $indent__s);

            li {
                display: inline-block;
            }
        }
    }

    .footer-copyright-fluid-container {
        @include lib-css(background-color, $secondary__color);
        @include lib-css(padding, $indent__s 0);

        text-align: center;

        .copyright {
            @include lib-css(font-size, $footer-copyright-font-size);
        }

        .copyright-address,
        .powered-by {
            .widget {
                @include lib-css(font-size, $footer-copyright-font-size);

                margin: 0;
            }
        }
    }
}
