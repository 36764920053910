.product-reviews-summary {
    align-items: center;
    display: flex;

    .products-grid & {
        justify-content: center;
    }

    .rating-summary {
        left: 0;
    }

    .reviews-actions .action.add {
        display: none;
    }
}

#tab-label-reviews-title {
    .data-switch-title .counter {
        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }
}
