.action.primary.checkout {
    @include lib-button-icon(
        $icon-padlock,
        $_icon-font-size        : 18px,
        $_icon-font-line-height : 22px
    );

    padding-left: $indent__xs;

    .minicart-wrapper & {
        @include lib-button-icon(
            $icon-padlock,
            $_icon-font-size        : 14px,
            $_icon-font-line-height : 16px
        );
    }
}
