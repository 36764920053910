.product-label {
    z-index: 2;
    position: absolute;

    .catalog-product-view & {
        // z-index for previous and next controls for the gallery
        // is set to 900
        z-index: 901;
    }

    .catalog-product-view &.top-right,
    .catalog-category-view &.top-right {
        @include label-position();
    }

    .catalog-product-view &.bottom-left,
    .catalog-category-view &.bottom-left {
        @include label-position('bottom-left');
    }

    .catalog-product-view &.product-page-small--mobile {
        transform: scale(0.7);
        transform-origin: 0 bottom;

        @include min-screen($screen__xs) {
            transform: scale(1);
        }
    }

    .catalog-category-view &.category-hidden--mobile {
        display: none;

        @include min-screen($screen__xs) {
            display: block;
        }
    }

    .fotorama-item.fotorama--fullscreen & {
        display: none;
    }
}

.catalog-category-view .product-items {
    .product-item-photo {
        position: relative;
    }
}
