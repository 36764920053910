.upsell-products {
    display: inline-block;

    &__title {
        @include lib-heading(h4);
        font-size: $h3__font-size;
    }

    &__row {
        display: inline-flex;
        width: 100%;

        .product-item-actions,
        .price-box {
            margin: 0;
        }
    }

    .upsell-products__row {
        &:not(.upsell-products__row--top):not(.upsell-products__row--bottom) {
            .upsell-products__row__data,
            .upsell-products__row__header {
                @include min-screen($screen__m) {
                    border-bottom: 1px solid $color-gray82 !important; // Overrides default table styles
                }
            }
        }
    }

    /* 206 is the full width of the upsell-product section divided by 6 products */
    & &__row__data,
    &__row__header {
        padding: $indent__s;
        width: 206px;
        min-width: 206px;
        display: inline-block;
    }

    &__row__data {
        text-align: center;

        &--current {
            @include min-screen($screen__m) {
                background-color: $color-solitude;
                border-right: 1px solid $upsell-current-product !important; // Overrides default table styles
                border-left: 1px solid $upsell-current-product !important; // Overrides default table styles
            }
        }

        &--current-top {
            @include min-screen($screen__m) {
                border-top: 1px solid $upsell-current-product !important // Overrides default table styles;
            }
        }

        &--current-bottom {
            @include min-screen($screen__m) {
                border-bottom: 1px solid $upsell-current-product !important // Overrides default table styles;
            }
        }
    }

    &__row__data.cell.label.remove {
        @include min-screen($screen__m) {
            border-bottom: none !important // Overrides default table styles;
        }
    }

    &__row__header {
        display: none;

        @include min-screen($screen__m) {
            display: inline-block;
        }
    }

    &__actions {
        display: flex;
        gap: $indent__s;
    }

    .upsell-products__actions__quantity-selector {
        display: flex;
        position: relative;

        .qty-decrease,
        .qty-increase {
            position: absolute;
            top: 25%;
            cursor: pointer;
            user-select:none;
        }

        .qty-increase {
            right: 0;
            margin-right: $indent__s;
        }

        .qty-decrease {
            margin-left: $indent__s;
        }

        .qty-input {
            text-align: center;
            height: 100%;
        }
    }

    .action.tocart.primary {
        font-size: $indent__s;
    }

    .price-including-tax.price-wrapper {
        display: none;
    }

    .cell.label.remove {
        @include max-screen($screen__m) {
            display: none;
        }
    }

    .price-box .price-container > span.price-excluding-tax {
        font-size: inherit;

        .price {
            font-size: inherit;
            font-weight: inherit;
        }
    }
}

// these are exact values used for the upsell-products tab on the hyva theme
.buying-options__tab {
    width: $upsell-product-tab-width;
    border: 1px solid $color-picton-blue;
    display: flex;
    background-color: $color-solitude;
    flex-direction: column;
    margin-top: -15px;
    margin-bottom: $indent__base;
    cursor: pointer;
    
    .buying-options__container a.buying-options__link {
        font-weight: $font-weight__semibold;
        padding: $indent__m;
        justify-content: space-between;
        display: flex;
        text-decoration: none;
        pointer-events: none;

        .price-wrapper.price-including-tax {
            display: none;
        }

        .price-wrapper.price-excluding-tax .price {
            font-size: $h4__font-size;
            font-weight: $font-weight__semibold;
        }
    }
}
