.products.wrapper.list {
    .product-item {
        @include lib-css(padding, $product-list-items-gutter);
        @include lib-css(margin-bottom, $product-list-items-gutter);

        &:last-child {
            margin-bottom: 0;
        }

        .product-item-info {
            @include min-screen($screen__m) {
                @include lib-vendor-prefix-flex-direction(row);
            }

            .product-item-photo {
                @include lib-css(margin-right, $indent__base);

                margin-bottom: 0;
                padding: 0;

                @include min-screen($screen__m) {
                    width: 25%;
                }
            }

            .product-item-actions {
                margin: auto 0;

                @include min-screen($screen__m) {
                    width: 35%;
                }

                @include min-screen($screen__l) {
                    width: 25%;
                }
            }

            .product-item-details {
                margin: auto 0;

                @include min-screen($screen__m) {
                    width: 40%;
                }

                @include min-screen($screen__l) {
                    width: 50%;
                }

                .product-item-name a {
                    @include screen($screen__m, $screen__l) {
                        @include lib-font-size(18);
                    }
                }

                .price {
                    @include screen($screen__m, $screen__l) {
                        @include lib-font-size(14);
                    }
                }
            }

            .product-item-inner {
                .product-item-actions .actions-secondary {
                    @include lib-css(padding-left, $indent__m);
                }
            }
        }
    }
}
