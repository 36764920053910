// Extends magento/vendor/magic42/magento2-theme/Magento_Checkout/styles/module/_checkout_button.scss

.minicart-wrapper {
    .block.block-minicart {
        .action.primary.checkout {
            @include lib-button-icon($icon-padlock);

            &:before {
                padding-right: $indent__xs;
            }
        }
    }
}
