// Extends vendor/snowdog/theme-blank-sass/styles/vendor/normalize/_lib.scss
// Snowdog variables are not available in this file

fieldset {
    border: 0;
    margin: 0;
}

h1.page-title {
    margin-bottom: 10px;
    margin-top: 20px;
}
