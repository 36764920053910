@include lib-font-face(
    $family-name: $font-family-name__muli,
    $font-path: '../fonts/Muli/muli-300',
    $font-weight: 300,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__muli,
    $font-path: '../fonts/Muli/muli-regular',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__muli,
    $font-path: '../fonts/Muli/muli-600',
    $font-weight: 600,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__muli,
    $font-path: '../fonts/Muli/muli-700',
    $font-weight: 700,
    $font-style: normal
);
