.catalog-category-view {
    .columns {
        clear: both;
    }

    .column.main {
        min-height: initial;

        .toolbar {
            padding: 0;

            .modes {
                .mode-grid,
                .mode-list {
                    &::before {
                        color: $color-indigo-dye;
                    }
                }
            }

            .sorter-action::before {
                color: $color-prussian-blue;
            }
        }

        .toolbar-products {
            margin-bottom: 0;
        }
    }

    .products.wrapper.grid {
        .product-items {
            margin-left: calc(#{$product-grid-items-gutter-s} * -1);
            margin-right: calc(#{$product-grid-items-gutter-s} * -1);

            @include min-screen($screen__m) {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .product-items .item.product-item {
            margin: $indent__xs;
            width: calc(#{100% / $product-grid-items-per-row-main-category-grid-screen-s} - #{$product-grid-items-gutter-s});

            @include min-screen($screen__m) {
                margin: 0 0 $product-grid-items-gutter-m $product-grid-items-gutter-m;
                width: calc(#{100% / $product-grid-items-per-row-layout-1-screen-m} - #{$product-grid-items-gutter-m});
            }

            @include min-screen($screen__l) {
                margin: 0 0 $product-grid-items-gutter-m $product-grid-items-gutter-l;
                width: calc(#{100% / $product-grid-items-per-row-layout-1-screen-l} - #{$product-grid-items-gutter-l});
            }

            @include min-screen($screen__xl) {
                margin: 0 0 $product-grid-items-gutter-l $product-grid-items-gutter-xl;
                width: calc(#{100% / $product-grid-items-per-row-layout-1-screen-xl} - #{$product-grid-items-gutter-xl});
            }
        }

        .product-items .item.product-item .action.tocart {
            margin: 0;

            @include min-screen($screen__m) {
                margin-left: $indent__xs;
            }
        }

        .product-items .actions-secondary {
            display: none;

            @include min-screen($screen__m) {
                display: flex;
            }

        }

        .product-item-actions .action.more.button {
            margin-left: 0;
            width: 100%;

            @include min-screen($screen__m) {
                margin-left: $indent__xs;
                width: 66.66%;
            }
        }

        .field.qty {
            display: none;

            @include min-screen($screen__m) {
                display: block;
            }
        }

        .action.primary.tocart {
            font-size: $font-size__s;
            width: 100%;

            // No suitable breakpoint in the snowdog theme
            @include min-screen(360px) {
                font-size: $button__font-size;
            }

            @include min-screen($screen__m) {
                width: 66.66%;
            }
        }

        .product-items .product-item .product.product-item-details a.product-item-link {
            font-size: $h4__font-size;

            @include min-screen($screen__m) {
                font-size: $font-size__base;
            }
        }
    }

    .slick-slider {
        @include lib-css(margin-left, 0 - $indent__s);

        .slick-slide {
            padding: 0;
        }
    }

    .category-view {
        .category-description {
            a {
                text-decoration: underline;
            }
        }
    }

    .product-reviews-summary.short {
        display: flex;
        max-width: $product-info-max-width;

        .rating-summary .rating-result > span::before {
            color: $star-review-colour;
        }
    }
}

.product-items {
    .column.main & {
        margin-left: 0;
    }

    .product-item {
        @include lib-css(border, $product-item-border);
        @include lib-css(border-radius, $indent__xs);

        .product-item-info {
            @include lib-vendor-prefix-display;
            @include lib-vendor-prefix-flex-direction;

            width: 100%;
            height: 100%;

            .product-item-photo {
                @include lib-css(padding, $indent__s);

                margin: 0 auto;
                width: initial;

                .product-image-photo {
                    position: initial;
                    max-height: $indent__xl * 6;
                }
            }

            .product-item-details {
                @include lib-vendor-prefix-display;
                @include lib-vendor-prefix-flex-direction;

                height: 100%;
            }

            .product-item-name {
                margin-bottom: auto;

                a {
                    @include lib-css(font-weight, $font-weight__bold);
                    @include lib-font-size(20);
                }
            }

            .price-box {
                @include lib-css(margin, $indent__s 0);
            }

            .stockmessage {
                @include lib-css(margin-bottom, $indent__s);
            }

            .price-excluding-tax,
            .price-including-tax {
                @include lib-font-size($font-size__s);

                display: inline-block;
                width: 100%;

                .price {
                    @include lib-font-size($font-size__l * 0.8);
                }
            }

            .price-excluding-tax .price {
                @include lib-font-size($font-size__l);
            }

            .product-item-actions {
                @include lib-vendor-prefix-display;
                @include lib-vendor-prefix-flex-wrap;

                .actions-primary {
                    display: block;
                    width: 100%;
                }

                .actions-secondary {
                    @include lib-css(margin-bottom, $indent__s);
                    @include lib-vendor-prefix-display;
                    @include lib-line-height($button-primary__line-height + get-border-width($button-primary__border)*2);

                    > .action {
                        @include lib-css(color, $primary__color);

                        background-color: transparent;
                        border: none;
                        display: block;
                    }

                    .towishlist:hover {
                        @include lib-icon-font-symbol($icon-wishlist-full);
                    }

                    .tocompare:hover {
                        @include lib-icon-font-symbol($icon-compare-full);
                    }
                }

                .action.delete,
                .action.edit,
                .action.more {
                    @include button-secondary;
                }

                .action.delete,
                .action.edit,
                .action.more,
                .action.tocart {
                    @include button-secondary-layout;
                }

                .box-tocart {
                    @include lib-vendor-prefix-display;

                    > .action.primary:first-child {
                        width: 100%;
                    }
                }

                .field.qty,
                .actions-secondary {
                    @include lib-css(margin-right, $indent__xs);

                    margin: 0 auto;
                    max-width: calc(#{(100% / 3)} - #{$indent__xs});

                    .control {
                        .quantity-control,
                        .input-text.qty {
                            @include lib-css(height, $button-primary__line-height);
                            @include lib-line-height($button-primary__line-height);

                            padding: 0 10%;
                        }
                    }
                }
            }
        }
    }
}
