table.table:not(.cart):not(.totals) {
    thead tr th {
        vertical-align: middle;
    }

    @include min-screen($screen__m) {
        &.table-bordered {
            @include lib-table-bordered;
        }
    }

    &.responsive {
        border-width: 0;

        thead {
            display: none;

            @include min-screen($screen__m) {
                display: table-header-group;
            }
        }

        tbody {
            tr {
                @include lib-css(margin-bottom, $indent__s);
                @include lib-css(border-bottom, $table__border-width $table__border_style $table__border_color);
                @include lib-vendor-prefix-display;
                @include lib-vendor-prefix-flex-direction;

                &:first-child {
                    @include lib-css(border-top, $table__border-width $table__border_style $table__border_color);
                }

                @include min-screen($screen__m) {
                    display: table-row;
                    margin: 0;
                }

                th {
                    @include lib-css(background, $table-head-bg);
                }

                td {
                    @include lib-vendor-prefix-display;

                    @include min-screen($screen__m) {
                        display: table-cell;
                    }

                    &:before {
                        @include lib-css(margin-right, $indent__xs);

                        content: attr(data-title) ": ";
                        font-weight: bold;
                        min-width: 30%;
                        width: 30%;

                        @include min-screen($screen__m) {
                            display: none;
                        }
                    }

                    &:empty {
                        display: none;

                        @include min-screen($screen__m) {
                            display: table-cell;
                        }
                    }

                    a {
                        display: contents;
                    }
                }
            }
        }
    }
}
