$read-more__line-height: $line-height__computed;
.read-more {
    p {
        line-height: $read-more__line-height;
        // Margin is used as spaces between lines, set this so it is equal to a line
        margin: 0 0 $read-more__line-height;
    }

    &.show-less { // Can't use hidden or hide class as it is already used elsewhere
        overflow: hidden;

        & ~ .read-more-link::before {
            content: '... ';
        }
    }
}
