.column.main .block-wishlist .block-content,
.column.main .block-compared-products-grid .block-content,
.column.main .block-viewed-products-grid .block-content,
.products.wrapper.grid {
    .product-items {
        @include lib-vendor-prefix-display;
        @include lib-vendor-prefix-flex-wrap;
        @include lib-css(margin-bottom, 0 - $product-grid-items-gutter-s);

        @include min-screen($screen__m) {
            @include lib-css(margin-left, 0 - $product-grid-items-gutter-m);
        }

        @include min-screen($screen__l) {
            @include lib-css(margin-left, 0 - $product-grid-items-gutter-l);
        }

        @include min-screen($screen__xl) {
            @include lib-css(margin-left, 0 - $product-grid-items-gutter-xl);
        }

        .product-item {
            @include lib-css(margin-bottom, $product-grid-items-gutter-s);
            @include lib-css(margin-left, $product-grid-items-gutter-s);
            @include lib-css(padding, $product-grid-items-gutter-s);

            margin-left: 0;
            width: calc(#{100% / $product-grid-items-per-row-layout-default} - #{$product-grid-items-gutter-s});

            @include min-screen($screen__m) {
                @include lib-css(margin-bottom, $product-grid-items-gutter-m);
                @include lib-css(margin-left, $product-grid-items-gutter-m);
            }

            @include min-screen($screen__l) {
                @include lib-css(margin-bottom, $product-grid-items-gutter-l);
                @include lib-css(margin-left, $product-grid-items-gutter-l);
            }

            @include min-screen($screen__xl) {
                @include lib-css(margin-bottom, $product-grid-items-gutter-xl);
                @include lib-css(margin-left, $product-grid-items-gutter-xl);
            }

            .page-layout-1column & {
                width: calc(#{100% / $product-grid-items-per-row-layout-1-screen-s} - #{$product-grid-items-gutter-s});

                @include min-screen($screen__m) {
                    width: calc(#{100% / $product-grid-items-per-row-layout-1-screen-m} - #{$product-grid-items-gutter-m});
                }

                @include min-screen($screen__l) {
                    width: calc(#{100% / $product-grid-items-per-row-layout-1-screen-l} - #{$product-grid-items-gutter-l});
                }

                @include min-screen($screen__xl) {
                    width: calc(#{100% / $product-grid-items-per-row-layout-1-screen-xl} - #{$product-grid-items-gutter-xl});
                }
            }

            .page-layout-2columns-left & {
                width: calc(#{100% / $product-grid-items-per-row-layout-2-left-screen-s} - #{$product-grid-items-gutter-s});

                @include min-screen($screen__m) {
                    width: calc(#{100% / $product-grid-items-per-row-layout-2-left-screen-m} - #{$product-grid-items-gutter-m});
                }

                @include min-screen($screen__l) {
                    width: calc(#{100% / $product-grid-items-per-row-layout-2-left-screen-l} - #{$product-grid-items-gutter-l});
                }

                @include min-screen($screen__xl) {
                    width: calc(#{100% / $product-grid-items-per-row-layout-2-left-screen-xl} - #{$product-grid-items-gutter-xl});
                }
            }

            .page-layout-2columns-right & {
                width: calc(#{100% / $product-grid-items-per-row-layout-2-right-screen-s} - #{$product-grid-items-gutter-s});

                @include min-screen($screen__m) {
                    width: calc(#{100% / $product-grid-items-per-row-layout-2-right-screen-m} - #{$product-grid-items-gutter-m});
                }

                @include min-screen($screen__l) {
                    width: calc(#{100% / $product-grid-items-per-row-layout-2-right-screen-l} - #{$product-grid-items-gutter-l});
                }

                @include min-screen($screen__xl) {
                    width: calc(#{100% / $product-grid-items-per-row-layout-2-right-screen-xl} - #{$product-grid-items-gutter-xl});
                }
            }

            .page-layout-3columns & {
                width: calc(#{100% / $product-grid-items-per-row-layout-3-screen-s} - #{$product-grid-items-gutter-s});

                @include min-screen($screen__m) {
                    width: calc(#{100% / $product-grid-items-per-row-layout-3-screen-m} - #{$product-grid-items-gutter-m});
                }

                @include min-screen($screen__l) {
                    width: calc(#{100% / $product-grid-items-per-row-layout-3-screen-l} - #{$product-grid-items-gutter-l});
                }

                @include min-screen($screen__xl) {
                    width: calc(#{100% / $product-grid-items-per-row-layout-3-screen-xl} - #{$product-grid-items-gutter-xl});
                }
            }

            .product-item-details {
                flex: 1;
                text-align: center;

                .product-item-name {
                    margin-bottom: auto;

                    a {
                        @include lib-font-size(18);
                    }
                }
            }

            .product-item-info .stockmessage {
                min-height: 0.95 * $indent__xl;
            }

            .actions-primary,
            .actions-secondary,
            .action.primary {
                text-align: center;
            }

            .product-item-inner {
                margin-top: auto;
            }
        }
    }
}

.grid.products-related {
    margin-top: 0;

    .product-items {
        margin-top: 0;
    }
}
