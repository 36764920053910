// Extends styles from:
// magento/vendor/snowdog/theme-blank-sass/Magento_Catalog/styles/_widgets.scss

// Inherits styles from:
// magento/app/design/frontend/magic42/flyingspares/Magento_Catalog/styles/components/_grid_layout.scss

.block-compared-products-grid,
.block-viewed-products-grid {
    .price-container {
        display: flex;
        flex-direction: column;

        .price-including-tax {
            margin-top: $indent__xs;
            order: 1;
        }
    }

    .product-items .product-item {
        .product-item-actions {
            margin: $indent__s 0;

            .actions-primary {
                order: 1;
                width: 66%;

                .action.primary {
                    width: 100%;
                }
            }

            .actions-secondary {
                line-height: 1;
                width: auto;

                .action.tocompare {
                    display: none;
                }
            }

            .product-item-description {
                margin: 0;

                .product-item-link {
                    @include button-secondary;

                    width: 100%;
                }
            }
        }
    }
}

.column.main .block-viewed-products-grid .block-content .products-grid {
    .product-items {
        @include max-screen($screen__xs) {
            flex-wrap: nowrap;
            gap: 0 $indent__s;
            overflow-x: auto;
            position: relative;
            scroll-snap-type: x mandatory;
            // 30px main content padding
            width: calc(100vw - 30px) !important;
        }
    }

    .product-item {
        @include max-screen($screen__xs) {
            flex: 0 0 100%;
            scroll-snap-align: center;
            max-width: 90%;
        }
    }
}

.widget.grid .products-grid.grid {
    @extend .products.wrapper.grid;
}

.page-layout-1column .page-main .block.widget .products-grid .product-item {
    &:nth-child(4n+1),
    &:nth-child(5n+1), {
        margin-left: 0;

        @include min-screen($screen__m) {
            margin-left: $product-grid-items-gutter-m;
        }

        @include min-screen($screen__l) {
            margin-left: $product-grid-items-gutter-l;
        }

        @include min-screen($screen__xl) {
            margin-left: $product-grid-items-gutter-xl;
        }
    }
}

.widget.grid {
    .product-items .product-item .product-item-actions {
        .actions-primary .primary {
            float: none;
            margin-left: 0;
            width: 100%;
        }

        .actions-secondary {
            display: none;
        }
    }
}
