.static-usps__content {
    @include lib-vendor-prefix-display;

    display: block;

    @include min-screen($screen__s) {
        display: flex;
        justify-content: space-around;
        width: 100%;
    }

    .static-usps__content-text {
        @include lib-vendor-prefix-display;
        @include lib-vendor-prefix-flex-wrap;

        flex-direction: column;
        padding: $indent__s $indent__s $indent__s 0;

        h3 {
            margin: 0 0 $indent__s;
        }
    }

    .static-usps__content-images {
        .row {
            margin-left: $indent__xs * -1;
            margin-right: $indent__xs;

            @include min-screen($screen__s) {
                // Reset bootstrap right margin to be 15px
                margin-right: ($indent__s + $indent__xs) * -1;
            }
        }

        img {
            width: 100%;
        }

        .static-usps__content-images-image {
            padding: $indent__xs;
        }
    }
}

.static-usps {
    margin: 0 auto;
    width: 100%;

    .title {
        @include min-screen($screen__s) {
            text-align: center;
        }
    }

    .static-usps__row {
        margin: 0;
    }
}
