@mixin radio-button() {
    input[type=radio] {
        position: absolute;
        opacity: 0;

        &:checked {
            & + span {
                background-color: $part-finder__secondary-color;
            }
        }
    }

    span {
        background-color: $secondary__color;
        border: 1px solid $part-finder__secondary-color;
        cursor: pointer;
        display: inline-block;
    }
}
