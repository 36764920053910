$price-font-size: 25px;
$special-price__color: #D32729;

.product-info-price,
.product-item-details,
.product-item-info .price-as-configured {
    .old-price {
        text-decoration: none;

        span.price-label {
            display: none;
        }
    }

    .special-price {
        margin: 0;

        span {
            &.price-label {
                @include lib-css(color, $special-price__color);
                @include lib-css(font-weight, $font-weight__bold);
                @include lib-font-size($font-size__l);
            }

            &.price-wrapper.price-including-tax {
                display: none;
            }
        }
    }
}

.product-info-price {
    @include lib-vendor-prefix-display;
    @include lib-vendor-prefix-flex-wrap;
    @include lib-font-size($font-size__base);

    > *:not(.price-box) {
        align-self: flex-end;
    }

    .special-price span.price-label {
        @include lib-font-size($price-font-size);
    }
}

.price-box {
    margin-right: auto;

    .price-container > span {
        display: inline-block;
        @include lib-css(margin-right, $indent__xs);

        &.price-excluding-tax,
        &.price-including-tax {
            &:before {
                content: '';
            }

            &:after {
                @include lib-font-size(14);
                content: ' ' attr(data-label);
            }

            .price {
                @include lib-font-size($price-font-size * 0.8);
                font-weight: normal;
            }
        }

        &.price-excluding-tax {
            .price {
                @include lib-font-size($price-font-size);
                font-weight: 700;
            }
        }
    }
}

.prices-tier.items {
    .price-excluding-tax .price::after {
        content: ' (exc VAT)';
        font-weight: normal;
    }

    .price-including-tax .price::after {
        content: ' (inc VAT)';
        font-weight: normal;
    }
}
