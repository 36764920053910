@mixin lib-button-ternary {
    border-radius: 0;

    @include lib-link-as-button();
    @include lib-button-primary(
        $_button-background: $color-picton-blue,
        $_button-background-active: darken($color-picton-blue, 10%),
        $_button-border-active: 1px solid $color-picton-blue,
        $_button-border: 1px solid $color-picton-blue,
        $_button-border-hover: 1px solid $color-picton-blue,
        $_button-background-hover: darken($color-picton-blue, 10%)
    );
}

.action.ternary {
    @include lib-button-ternary();
}
