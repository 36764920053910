.page-product-grouped {
    &.catalog-product-view {
        .product-info-grouped > * {
            max-width: 100%;
        }
    }

    .add-all-to-cart {
        .box-tocart,
        .actions,
        .action.primary {
            @include min-screen($screen__l) {
                width: 100%;
            }
        }

        .action.primary {
            text-transform: none;
            @include lib-button-ternary();
        }
    }

    .diagram-controls {
        display: flex;
        left: 5px;
        position: absolute;
        top: 5px;

        &,
        .diagram-control {
            cursor: pointer;
        }

        .diagram-control {
            display: inline-block;
            line-height: $exploded-diagram__control-line-height;
            margin: 0 $indent__xs;

            &:first-child {
                margin-left: 0;
            }

            &:not(.diagram-control-reset) {
                background: $color-white;
            }

            &--image {
                background: $exploded-diagram__icon-color;
                border-radius: 3px;
                padding: $indent__xs;

                &:hover {
                    background: $exploded-diagram__icon-color--hover;
                }
            }
        }
    }

    .exploded-diagram-back-to-top {
        bottom: 12px; // Aligns the button with the fixed help icon
        left: 50%;
        transform: translateX(-50%);
        visibility: hidden;
        z-index: 1;

        @include min-screen($screen__l) {
            display: none;
        }
    }

    #productexploded_section {
        display: flex;
        flex-wrap: wrap;
    }

    .panzoom-container {
        cursor: url("../images/move-cursor-icon.png"), auto;
        max-height: 100vh;
        overflow: hidden;
    }

    .pe-attribute {
        .stock-label {
            padding-right: $indent__xs;

            @include min-screen($screen__m) {
                padding-right: 0;
                display: block;
            }
        }

        &.pe-attribute__condition {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            strong {
                margin-right: $indent__xs;
            }
        }

        &:not(.pe-attribute__stock-message) {
            padding-bottom: 5px;
        }

        &.pe-attribute__stock-message {
            width: 100%;

            &:before {
                content: 'Availability: ';
                font-weight: $font-weight__bold;

                @include min-screen($screen__m) {
                    display: none;
                }
            }

            @include min-screen($screen__m) {
                background: $exploded-diagram-item__availaility__background;
                border: 1px solid $exploded-diagram-item__availaility__background;
                border-radius: 3px;
                padding: $indent__xs;
                max-width: $exploded-diagram-item__name__width;
                text-align: center;
            }

            &--in-stock {
                @include lib-icon-font(
                    $icon-checkmark,
                    $_icon-font-color: $color-shamrock,
                    $_icon-font-size: 24px,
                    $_icon-font-line-height: 20px
                );

                @include min-screen($screen__m) {
                    background: $exploded-diagram-item__availaility__background--in-stock;
                    border: 1px solid $exploded-diagram-item__availaility__background--in-stock;
                }
            }

            &--no-longer-available {
                @include min-screen($screen__m) {
                    background: none;
                    border: none;
                }
            }
        }
    }

    .pe-product-image,
    .table-wrapper.grouped {
        width: 100%;

        @include min-screen($screen__l) {
            width: calc(50% - #{$indent__xs});
        }
    }

    .table-wrapper.grouped {
        margin-left: 0;

        @include min-screen($screen__l) {
            margin-left: $indent__s;
        }
    }

    .grouped__item {
        border: 1px solid $exploded-diagram-item__border-color;
        border-radius: $exploded-diagram-item__border-radius;
        display: flex;
        margin: $indent__base 0;

        > * {
            padding: $indent__xs;
        }

        &:first-child {
            @include min-screen($screen__l) {
                margin-top: 0;
            }
        }

        .add-to-cart-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: right;
            align-items: center;

            > * {
                margin: $indent__xs 0;
            }

            .action.ternary {
                margin-left: $indent__xs;
            }
        }

        &.pe-highlight {
            border: 1px solid $exploded-diagram__item--highlight;
            border-radius: $indent__xs;
            border-left-width: $indent__xs;
        }
    }

    .pe-image-container {
        display: block;
        transform-origin: 50% 50% !important; // Overide inline styles

        .pe-label-area {
            border-color: $exploded-diagram__label__border-color;

            &.pe-active {
                border-color: $exploded-diagram__label__border-color--active;
            }
        }
    }

    .pe-product-image {
        align-self: flex-start;
        border: 1px solid $exploded-diagram__border-color;
        border-radius: $exploded-diagram__border-radius;
        position: relative;
        top: 0;

        @include min-screen($screen__l) {
            position: sticky;
            position: -webkit-sticky;
        }
    }

    .pe-product-title {
        text-align: left;
    }

    .box-tocart button.action.tocart {
        width: 100%;
    }

    .pe-qty-icon {
        cursor: pointer;
        display: inline-block;
        user-select: none;
    }

    .pe-qty-cell {
        padding: 0 $indent__s;
        border: 1px solid $exploded-diagram-item__qty-selector__border-color;
        line-height: 4rem;
        margin-right: $indent__xs;

        input.qty {
            border: none;
            text-align: center;
            width: 45px;
        }
    }

    .pe-number-column {
        width: min-content;
    }

    .pe-title-column {
        width: 100%;
        padding: $indent__s;

        @include min-screen($screen__xs) {
            padding: $indent__base;
        }

        .product-item-sku {
            margin-bottom: $indent__xs;
        }
    }

    .pe-thumbnail-column {
        min-width: $exploded-diagram__item__thumbnail--width;
    }

    .price-box .price-container {
        .price-excluding-tax .price {
            font-size: $font-size__l;
            font-weight: $font-weight__bold;
        }

        .price-including-tax .price {
            font-size: $font-size__base;
            font-weight: $font-weight__regular;
        }

        .price-excluding-tax,
        .price-including-tax {
            display: block;
        }
    }

    .price-box {
        margin-top: 0;
    }

    .price-cart-wrapper,
    .name-stock-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .price-cart-wrapper {
        align-items: center;
    }

    .name-stock-wrapper {
        align-items: flex-start;
        margin-bottom: $indent__xs;
    }

    .grouped__item .product-item-name {
        font-size: $font-size__xl;
        line-height: $line-height__s;

        @include min-screen($screen__m) {
            width: calc(100% - #{$exploded-diagram-item__name__width});
            padding-right: $indent__s;
        }
    }

    .price-cart-wrapper {
        margin-top: $indent__xs;
    }
}
