.top-container {
    border-bottom: $navigation-level0-item__border;

    .usp-row {
        font-size: $font-size__s;
        padding: 0;

        .usp-blocks-list.usp-xl-3-3 {
            .usp-blocks-item {
                display: table;
                line-height: inherit;
                margin: 0;

                p {
                    display: table-cell;
                    line-height: inherit;
                    vertical-align: middle;
                }

                a {
                    line-height: inherit;

                    img {
                        height: $usp-row-line-height;
                        vertical-align: top;
                    }
                }


                @include min-screen($usp-row-screen__xl) {
                    &.slide:nth-child(1) {
                        text-align: left;
                        width: 20%;
                    }

                    &.slide:nth-child(2) {
                        left: 80%;
                        text-align: right;
                        width: 20%;
                    }

                    &.slide:nth-child(3) {
                        left: 20%;
                        width: 60%;
                    }
                }
            }
        }
    }
}
