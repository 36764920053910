//
//  Variables for icons-flyingspares-theme
//  ---------------------------------------------
$icon-arrow-up-thin     : '\es27';
$icon-account           : '\e628';
$icon-gift-registry     : '\e629';
$icon-present           : '\e62a';
$icon-padlock           : '\e62b';
$icon-padlock__outline  : '\e62c';
$icon-fb                : '\e62d'; // Facebook
$icon-fb-sq             : '\e62e'; // Facebook Filled Square
$icon-fb-ci             : '\e62f'; // Facebook Filled Circle
$icon-fb-ci__outline    : '\e630'; // Facebook Outline Circle
$icon-tw                : '\e631'; // Twitter
$icon-tw-sq             : '\e632'; // Twitter Filled Square
$icon-tw-ci             : '\e633'; // Twitter Filled Circle
$icon-tw-ci__outline    : '\e634'; // Twitter Outline Circle
$icon-yt-txt            : '\e635'; // YouTube Text Logo
$icon-yt-txt-sq         : '\e636'; // YouTube Text Logo Filled Square
$icon-yt-txt-ci         : '\e637'; // YouTube Text Logo Filled Circle
$icon-yt-txt-ci__outline: '\e648'; // YouTube Text Logo Outline Circle
$icon-yt                : '\e639'; // YouTube
$icon-yt-sq             : '\e63a'; // YouTube Filled Square
$icon-yt-ci             : '\e63b'; // YouTube Filled Circle
$icon-yt-ci__outline    : '\e63c'; // YouTube Outline Circle
$icon-ig                : '\e63d'; // Instagram
$icon-ig-sq             : '\e63e'; // Instagram Filled Square
$icon-ig-ci             : '\e63f'; // Instagram Filled Circle
$icon-ig-ci__outline    : '\e640'; // Instagram Outline Circle
$icon-equalizer         : '\e992'; // Filter By Icon
