.notification-container {
    display: flex;

    .input-fields.fieldset {
        flex: 1;
    }

    .actions-toolbar.amxnotif_guest_action {
        margin-left: $indent__xs !important; // Needed to overide base style
        min-width: auto;
    }

    .submit {
        align-items: center;
        display: flex;
        height: $form-element-input__height;
        justify-content: center;
        line-height: $form-element-input__height;
    }
}
