// grid
@import '../../node_modules/bootstrap/scss/bootstrap-grid';

// mixins
@import '../../node_modules/bootstrap/scss/mixins/image';
@import '../../node_modules/bootstrap/scss/mixins/border-radius';
@import '../../node_modules/bootstrap/scss/mixins/box-shadow';
@import '../../node_modules/bootstrap/scss/vendor/rfs';
@import '../../node_modules/bootstrap/scss/mixins/clearfix';
@import '../../node_modules/bootstrap/scss/mixins/transition';
@import '../../node_modules/bootstrap/scss/mixins/hover';
@import '../../node_modules/bootstrap/scss/mixins/background-variant';
@import '../../node_modules/bootstrap/scss/mixins/table-row';

// buttons
@import '../../node_modules/bootstrap/scss/mixins/buttons';
@import '../../node_modules/bootstrap/scss/mixins/gradients';

// tooltip
@import '../../node_modules/bootstrap/scss/mixins/reset-text';

// text
@import '../../node_modules/bootstrap/scss/mixins/text-truncate';
@import '../../node_modules/bootstrap/scss/mixins/text-emphasis';
@import '../../node_modules/bootstrap/scss/mixins/text-hide';
@import '../../node_modules/bootstrap/scss/mixins/deprecate';

// stylesheets
@import '../../node_modules/bootstrap/scss/images';
@import '../../node_modules/bootstrap/scss/carousel';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/button-group';
@import '../../node_modules/bootstrap/scss/utilities/borders';
@import '../../node_modules/bootstrap/scss/tooltip';
@import '../../node_modules/bootstrap/scss/utilities/overflow';
@import '../../node_modules/bootstrap/scss/utilities/position';
@import '../../node_modules/bootstrap/scss/utilities/text';
@import '../../node_modules/bootstrap/scss/utilities/align';
@import '../../node_modules/bootstrap/scss/utilities/background';
@import '../../node_modules/bootstrap/scss/utilities/display';
@import '../../node_modules/bootstrap/scss/utilities/spacing';
@import '../../node_modules/bootstrap/scss/tables';

// fixes
.swatch-option, .switch {
    box-sizing: content-box;
}

.table .col {
    width: auto;
}

// Fix the underline on category descriptions (Bootstrap is removing it)
.category-description a {
    text-decoration: underline;
}

.totals,
.totals-tax {
    .mark {
        background-color: transparent;
    }
}
