.field-tooltip {
    .field-tooltip-content {
        // Positions the tooltip below the field
        // $checkout-tooltip-content__padding / 2 is the height of the arrow
        transform: translate(calc(-100% - #{$checkout-tooltip-content__padding}),
                calc(100% + #{$checkout-tooltip-content__padding / 2}));

        // Default positioning on the right
        @include min-screen($screen__m) {
            transform: translate(0);
        }

        &:before,
        &:after {
            @include lib-css(top, -($checkout-tooltip-content__padding));
            @include lib-css(left, $checkout-tooltip-content__width + $checkout-tooltip-icon-arrow__left);

            @include lib-arrow(
                    $_position: down,
                    $_size: $checkout-tooltip-icon-arrow__font-size,
                    $_color: $checkout-tooltip-content__background-color
            );

            @include min-screen($screen__m) {
                @include lib-arrow(
                        $_position: left,
                        $_size: $checkout-tooltip-icon-arrow__font-size,
                        $_color: $checkout-tooltip-content__background-color
                );

                @include lib-css(left, $checkout-tooltip-icon-arrow__left);
                @include lib-css(top, $checkout-tooltip-content__padding);
            }

            margin-top: 0;
        }
    }
}
