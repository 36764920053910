.social-icon {
    background-position: center;
    background-size: cover;
    display: inline-block;
    vertical-align: bottom;

    &:hover {
        text-decoration: none;
    }

    &.facebook {
        @include lib-icon-font(
            $_icon-font-content: $icon-fb-ci__outline
        );
    }

    &.instagram {
        @include lib-icon-font(
            $_icon-font-content: $icon-ig-ci__outline
        );
    }

    &.twitter {
        @include lib-icon-font(
            $_icon-font-content: $icon-tw-ci__outline
        );
    }

    &.youtube {
        @include lib-icon-font(
            $_icon-font-content: $icon-yt-ci__outline
        );
    }
}
