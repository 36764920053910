#homepage-banner {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
    right: 50%;
    width: 100vw;

    &,
    .slick-list {
        min-height: 40vw;

        @include min-screen($screen__m) {
            min-height: 30vw;
        }
    }

    &:not(.slick-initialized) {
        background-color: $color-indigo-dye;
    }

    .slick-slide {
        img,
        source {
            width: 100%;
        }
    }
}

.slick-slide[data-amsrc] {
    height: 1px !important;
}

.slick-slider {
    &:not(.slick-initialized) {
        picture {
            display: none;
        }
    }

    .slick-next,
    .slick-prev {
        z-index: 2;
    }

    .slick-next {
        right: rem($indent__m);
    }

    .slick-prev {
        left: rem($indent__m);
    }

    div.slick-slide {
        padding: 0;
        position: relative;

        a {
            @include lib-css(color, $color-white);
        }

        .block {
            @include lib-css(background, rgba($color-black, 0.7));
            @include lib-css(bottom, 0);
            @include lib-css(color, $color-white);

            left: 0;
            position: absolute;
            right: 0;
            text-align: center;
        }
    }

    button {
        display: none;
    }
}

.slick-slider:hover {
    button {
        display: block;
    }
}

.slick-slider.full-width {
    .slick-slide {
        padding: 0;

        .text {
            margin: 0;
        }
    }
}

#category-image:not(.slick-initialized) {
    img:not(:first-child) {
        display: none;
    }
}
