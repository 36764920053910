// cart_page_product_thumbnail has been reused for the thumbnails
// This is defined in magento/app/design/frontend/magic42/flyingspares/etc/view.xml
$exploded-diagram__item__thumbnail--width: 110px;

$exploded-diagram__icon-color: $color-prussian-blue;
$exploded-diagram__icon-color--hover: $color-blue-whale;
$exploded-diagram__control-line-height: 12px;

$exploded-diagram__border-color: $color-gray82;
$exploded-diagram__border-radius: $indent__xs;

$exploded-diagram__label__border-color: $color-prussian-blue;
$exploded-diagram__label__border-color--active: $color-picton-blue;

$exploded-diagram-item__border-color: $color-gray80;
$exploded-diagram-item__border-radius: $indent__xs;
$exploded-diagram__item--highlight: $exploded-diagram__label__border-color--active;

$exploded-diagram-item__availaility__background: $color-polar;
$exploded-diagram-item__availaility__background--in-stock: $color-white-ice;

$exploded-diagram-item__name__width: 100px;
$exploded-diagram-item__qty-selector__border-color: $color-picton-blue;
