.breadcrumbs {
    margin-top: $indent__s;
    margin-bottom: 0;

    .items {
        .item:not(:last-child) {
            > a {
                text-decoration: underline;
            }
        }
    }
}
