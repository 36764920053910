.contact-information-items {
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
        margin: 0;

        &:not(:first-of-type) {
            @include max-screen($screen__l) {
                display: none;
            }
        }

        a {
            color: $color-white;
        }
    }

    a.social-icon {
        height: 18px;
        margin-right: $indent__xs;
        width: 18px;

        &,
        &::before {
            vertical-align: top;
        }
    }

    &.footer-icons {
        .social-icon {
            font-size: $font-size__xl;
            height: $indent__l;
            width: $indent__m;
        }
    }
}
