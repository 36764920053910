.vesblog-page {
    .post-list {
        .blog-grid {
            margin: 0;
        }

        .post-item .post-image {
            @include max-screen($screen__l) {
                margin-bottom: rem($indent__s);
                width: 100%;
            }
        }
    }

    .block .opener {
        line-height: 1;
    }

    .blog-posts {
        .row {
            > * {
                display: flex;
                flex-direction: column;
                margin-bottom: $indent__l;
            }

            .post-item {
                height: 100%;
                padding: 0;
            }
        }

        .post-readmore {
            padding: 0 $indent__xs $indent__s $indent__xs;
            text-align: center;
            text-transform: uppercase;

            a {
                width: 100%;
            }
        }

        .post-summary {
            margin-top: auto;
        }

        .post-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }
}
