.page-footer .block.newsletter {
    .form.subscribe {
        display: flex;
        flex-wrap: wrap;

        .field {
            width: 60%;

            @include min-screen($screen__m) {
                flex-grow: 1;
                width: auto;
            }
        }

        .actions {
            width: 40%;

            @include min-screen($screen__m) {
                width: auto;
            }
        }

        .field-recaptcha {
            margin-top: 10px;
            width: 100%;

            .grecaptcha-badge {
                box-shadow: unset !important; // Override inline styling
            }
        }
    }
}
