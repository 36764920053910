//
//  Typography variables
//  _____________________________________________

//
//  Fonts
//  ---------------------------------------------

//  Path
$icons__font-path: '../fonts/Flyingspares-Theme-Icons/Flyingspares-Theme-Icons';

//  Names
$icons__font-name: 'icons-flyingspares-theme';
