.breadcrumbs {
    .item {
        @include max-screen($screen__m) {
            &:last-child {
                display: none;
            }

            &:not(last-child):nth-last-child(2):after {
                content: none;
            }
        }
    }
}
