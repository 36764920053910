.toolbar {
    .filter-by-title-container {
        align-items: center;
        display: flex;
        float: left;

        @include min-screen($screen__m) {
            display: none;
        }

        & > span {
            border: $input-text__border;
            cursor: pointer;
            padding: 0 $indent__s;

            @include lib-icon-font(
                $icon-equalizer,
                $_icon-font-size       : 16px,
                $_icon-font-line-height: 32px,
                $_icon-font-color      : $color-prussian-blue,
                $_icon-font-color-hover: $header-icons-color-hover,
                $_icon-font-text-hide  : true,
                $_icon-font-position   : after,
                $_icon-font-display    : none
            );

            &:after {
                padding-left: $indent__xs;
            }
        }
    }

    .sorter {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        padding-top: 0;

        .sorter-label {
            white-space: nowrap;
        }

        .sorter-options {
            width: inherit;
        }
    }
}

.toolbar-wrapper {
    // Max screen used for simplicity and to prevent adding extra styles to reset defaults
    @include max-screen($screen__m) {
        > * {
            height: 32px;
            text-align: left;
            width: 50%;
        }
    }
}

.block.filter {
    display: none;

    &.active {
        display: inline;
    }

    @include min-screen($screen__m) {
        display: inline;
    }
}

// Removes the filter by button from the toolbar after the product grid
.products.wrapper ~ .toolbar .filter-by-title-container {
    display: none;
}
