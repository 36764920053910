//
// Catalog Module Variable
// ---------------------------------

// Category View Page
$product-item-border                                : 1px solid $color-gray82;

$product-actions-height                             : rem(40px);
$product-action-icon-color                          : $color-gray82;
$product-action-icon-color-hover                    : $primary__color;
$upsell-current-product                             : rgba(113, 204, 255, 0.56);

// List View
$product-list-items-gutter                          : rem(20px);

// Grid View
$product-grid-items-gutter-s                        : $indent__s;
$product-grid-items-gutter-m                        : $indent__s;
$product-grid-items-gutter-l                        : 15px;
$product-grid-items-gutter-xl                       : 15px;

$product-grid-items-per-row-layout-default          : 1;

$product-grid-items-per-row-layout-1-screen-s       : 1;
$product-grid-items-per-row-layout-1-screen-m       : 2;
$product-grid-items-per-row-layout-1-screen-l       : 4;
$product-grid-items-per-row-layout-1-screen-xl      : 4;

$product-grid-items-per-row-layout-2-left-screen-s  : 1;
$product-grid-items-per-row-layout-2-left-screen-m  : 2;
$product-grid-items-per-row-layout-2-left-screen-l  : 3;
$product-grid-items-per-row-layout-2-left-screen-xl : 4;

$product-grid-items-per-row-layout-2-right-screen-s : 1;
$product-grid-items-per-row-layout-2-right-screen-m : 2;
$product-grid-items-per-row-layout-2-right-screen-l : 3;
$product-grid-items-per-row-layout-2-right-screen-xl: 4;

$product-grid-items-per-row-layout-3-screen-s       : 1;
$product-grid-items-per-row-layout-3-screen-m       : 2;
$product-grid-items-per-row-layout-3-screen-l       : 3;
$product-grid-items-per-row-layout-3-screen-xl      : 4;

$product-grid-items-per-row-main-category-grid-screen-s  : 2;

// Product View Page
$upsell-product-tab-width                           : 330px;
