[class *= "securepayment-index"] .page-main {
    margin: 0 auto;
    max-width: 0.9 * $screen__m;
}

.secure-payment {
    .action.primary {
        @extend .abs-button-l;
        margin-top: 17.5px;
    }

    .bank-details {
        text-decoration: underline;
    }

    .buttons {
        align-items: flex-end;
        flex-direction: column;
        display: flex;
    }

    .card {
        background-color: $color-gray94;
        border: 1px solid $color-gray82;
        border-bottom-width: $indent__s;
        display: inline-block;
        padding: $indent__s * 1.5;
        width: 100%;

        fieldset {
            display: flex;
            max-width: $screen__s;

            @include max-screen($screen__m) {
                flex-direction: column;
            }

            > div {
                flex: 1;
            }

            > div + div {
                @include max-screen($screen__m) {
                    margin-top: $indent__s;
                }

                @include min-screen($screen__m) {
                    margin-left: $indent__base;
                }
            }

            .is-delivery-same {
                label {
                    vertical-align: sub;
                }

                input {
                    display: inline-block;
                    margin-left: $indent__xs;
                    vertical-align: middle;
                }
            }
        }

        fieldset + fieldset {
            margin-top: $indent__s;
        }

        h2 {
            margin-top: 0;
        }

        input,
        select {
            display: block;
        }

        label + input,
        label + select {
            margin-top: $indent__xs;
        }

        table {
            td,
            th {
                vertical-align: top;

                &:first-child {
                    padding-left: 0;
                }
            }

            td:first-child:not(.order-no) {
                font-weight: $font-weight__bold;
            }
        }
    }

    .card-wrapper {
        margin: $indent__base 0;
    }

    .phone {
        &__label {
            display: block;
        }

        input.phone__item,
        select.phone__item {
            display: inline;
        }

        input.phone__item {
            margin-top: $indent__xs;
        }

        &__item--code {
            width: 100px;
        }

        &__item--number {
            // 100px is the width of the item
            // 4 px is for the input and select border
            width: calc(100% - 104px);
        }
    }
}

.phone__item.phone__item--code {
    appearance: none;
    opacity: 0.5;
    pointer-events: none;
}

