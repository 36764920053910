@mixin flex-grid($columns: 3, $width__offset: 10px) {
    @include lib-list-reset-styles;
    @include lib-vendor-prefix-display;

    flex-flow: row wrap;
    margin-left: $width__offset * -1;

    & > div,
    & > li {
        margin-left: $width__offset;
        width: 100%;

        img {
            width: 100%;
        }

        @include min-screen($screen__m) {
            width: calc((100% / #{$columns}) - #{$width__offset});
        }
    }
}
