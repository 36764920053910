$thumbnail__height: 3.5 * $indent__xl;
$thumbnail__width: 4.5 * $indent__xl;

.catalog-category-view .page-main {
    position: relative;

    @include max-screen($screen__m) {
        display: flex;
        flex-direction: column;
    }

    .category-description,
    .page-title-wrapper {
        @include max-screen($screen__m) {
            order: -1;
        }
    }

    .category-image,
    .category-image + .category-description,
    .page-title-wrapper {
        @include min-screen($screen__m) {
            width: calc(100% - #{$indent__base} - #{$thumbnail__width});
        }
    }

    .category-image + .category-description {
        @include min-screen($screen__m) {
            min-height: 4 * $indent__m;
        }
    }

    .category-image + .page.messages {
        @include min-screen($screen__m) {
            min-height: 3 * $indent__xl;
        }
    }
}

.category-image {
    margin-bottom: 0;

    .thumbnail-button {
        display: none;

        @include min-screen($screen__m) {
            cursor: pointer;
            display: inline-block;
            float: right;
            position: absolute;
            right: $indent__base;
            top: $indent__base;
            text-align: center;
            z-index: 2;
        }

        .image {
            border: $product-item-border;

            @include min-screen($screen__m) {
                margin: 0 auto;
                max-height: $thumbnail__height;
                max-width: $thumbnail__width;
            }
        }
    }

    .large-image {
        display: none;
        margin-bottom: $indent__base;
        order: 2;
        width: 100%;

        @include max-screen($screen__m) {
            display: block !important;
        }
    }
}
