#msp-recaptcha-checkout-inline-login-wrapper,
form .field-recaptcha {
    margin-top: $form-field__vertical-indent;

    @include min-screen($screen__m) {
        margin-top: $form-field__vertical-indent__desktop;
    }
}

.checkout-index-index.page-layout-checkout .g-recaptcha{
    margin-top: $recaptcha_top_spacing;
    margin-left: $recaptcha_left_spacing;
}

.form-create-account,
.form-login,
.form.password.forget,
.review-form {
    .field-recaptcha {
        @include min-screen($screen__m) {
            @include abs-margin-for-forms-desktop();
        }
    }

    .actions-toolbar {
        @include min-screen($screen__m) {
            display: flex;
        }

        > .primary,
        > .secondary {
            float: none;
        }

        > .primary {
            width: 100%;

            @include min-screen($screen__m) {
                width: 50%;
            }
        }

        > .secondary {
            line-height: $indent__l;

            @include min-screen($screen__m) {
                margin-left: auto;
            }
        }
    }
}
