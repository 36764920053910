.table-checkout-shipping-method {
    width: 100%;

    .col.col-carrier {
        display: none;
    }

    td:first-child,
    th:first-child {
        max-width: 4 * $indent__m;
    }

    tbody tr td,
    thead tr th {
        display: flex;
        justify-content: center;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
    }
}
