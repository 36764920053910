.cookie-status-message {
    display: none;
}

header.page-header {
    background-color: $primary__color;
    margin-bottom: 0;

    .panel.wrapper {
        background-color: $secondary__color;
        color: $color-alabaster;
        text-transform: uppercase;
    }

    .header.panel {
        > .header.links {
            @include min-screen($screen__m) {
                > li:not(:last-child) {
                    @include separator(after);

                    &:after {
                        margin-right: 0;
                    }

                    margin: 0;
                }
            }

            a {
                color: $color-alabaster;
            }
        }

        > .switcher {
            @include separator;

            float: right;
            font-size: $font-size__base;
            margin-right: 0;

            .options .action.toggle:after {
                line-height: $header-panel-content-height;
            }
        }

        > .header.left {
            display: none;
            float: left;
            font-size: $font-size__base;

            .widget {
                margin: 0;
            }

            .separator {
                @include separator;
            }

            @include min-screen($screen__m) {
                display: inline-block;
            }
        }
    }

    .header.content {
        .logo img {
            max-height: $logo__max-height--mobile;

            @include min-screen($screen__m) {
                max-height: initial;
            }

            @include max-screen($screen__m) {
                width: auto;
            }
        }

        @include min-screen($screen__m) {
            display: flex;
            justify-content: flex-start;

            .logo {
                margin: -$header-content-logo-margin auto $header-content-logo-margin 0;
                flex: 1;
            }

            .block-search {
                align-items: center;
                flex: 1;
            }

            .minicart-wrapper {
                flex: 1;
                margin: 0;
                order: 1;

                .showcart {
                    float: right;
                }
            }
        }

        .minisearch {
            .actions .search {
                background-color: $secondary__color;
                border-radius: 0;
                color: $color-alabaster;
                right: 0;
                width: $header-search-action-submit-width;

                &[disabled] {
                    background-color: $secondary__color;
                    opacity: 1;
                }
            }
        }

        .minicart-wrapper .action.showcart {
            .counter.qty {
                background: $color-red9;
            }
        }
    }
}
