//
// Shame File
// ---------------------------
// Only to be used to override CSS styles where inline styles may be present or ID selectors have been used
// ¯\_(ツ)_/¯
// ---------------------------

.product-image-wrapper {
    padding-bottom: 100% !important;
}
.product-item-info .product-image-container {
    width: 100% !important;
}
