.switcher {
    .switcher-dropdown {
        display: none;
    }

    .switcher-options {
        &.active {
            .switcher-dropdown {
                display: block;
            }
        }
    }
}
