.cms__grid {
    @include flex-grid;

    .cms__grid__item {
        overflow: hidden;
        position: relative;

        .cms__grid__item__text {
            background: rgba(0, 0, 0, 0.5);
            bottom: 0;
            position: absolute;
            width: 100%;
            z-index: 1;
        }

        .cms__grid__item__text__overlay {
            font-size: $h2__font-size;
            margin: $indent__s 0;
            text-align: center;
            text-transform: uppercase;

            a,
            a:hover,
            a:visited {
                color: $color-white;
            }
        }

        img {
            height: 100%;
            position: relative;
            transition: all 400ms;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}
