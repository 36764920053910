// Overides for magento/vendor/amasty/advanced-review/view/base/web/css/source/mkcss/amreview.less

@include min-screen($screen__m) {
    .review-details.amreview-author-details {
        .amreview-recommended {
            // A relative unit can not be used
            max-width: 100px;
        }
    }
}
