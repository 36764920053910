.comments-list {
    div.comment-content {
        margin-bottom: $indent__s;
        margin-left: 0;
    }

    > ul {
        > .even,
        > .odd {
            padding-left: 0;
        }
    }

    .avatar {
        display: none;
    }
}
