$product-info-max-width: 500px;

.catalog-product-view {
    .product-options-wrapper {
        margin-bottom: $indent__base;
    }

    @include min-screen($screen__m) {
        &.page-layout-1column {
            .product.media {
                width: 42%;
            }

            .column.main > .product-add-form,
            .product-info-main {
                @include lib-css(margin-left, $indent__s);
                width: calc(58% - #{$indent__s});
            }
        }
    }

    .page-title-wrapper {
        order: -2;

        .page-title {
            font-size: $h1__font-size-mobile;

            @include min-screen($screen__m) {
                font-size: $h1__font-size-desktop;
            }
        }
    }

    .product-reviews-summary {
        display: flex;
        max-width: $product-info-max-width;

        .rating-summary .rating-result > span::before {
            color: $star-review-colour;
        }

        .reviews-actions {
            display: flex;
            flex-grow: 1;

            .action.view {
                flex-grow: 1;
                font-size: $font-size__s;
                font-weight: bold;

                &::before {
                    content: '(';
                }

                &::after {
                    content: ')';
                }
            }

            .action.add {
                text-decoration: underline;
            }
        }
    }

    .product-additional-attributes-wrapper {
        margin-top: $indent__s;

        .additional-attributes {
            @include lib-vendor-prefix-display;
            @include lib-vendor-prefix-flex-direction;

            list-style: none;
            padding: 0;

            .attribute {
                &.stockmessage {
                    order: 100;
                }

                .label {
                    font-weight: bold;
                }
            }
        }
    }

    .product-info-main {
        font-size: $font-size__base;

        a {
            @include lib-css(color, $primary__color);
            text-decoration: underline;
        }

        > * {
            @include lib-css(max-width, $product-info-max-width);
        }

        .additional-information-link-wrapper {
            margin-top: $indent__s;

            a {
                text-decoration: none;
            }
        }

        .read-more-link {
            &:after {
                content: '';
            }
        }
    }

    .product-info-price {
        @include lib-css(margin, $indent__s 0);
    }

    .product.attribute.stockmessage {
        font-weight: bold;
        margin-top: $indent__s;
        margin-bottom: $indent__m;
    }

    .product.attribute.description {
        margin-top: $indent__s;
        max-width: none;
    }

    .product.attribute.overview {
        margin: 0;
    }

    .product-add-form {
        display: inline-block;
    }

    .product-social-links {
        display: inline-block;
        margin: 16px;

        .product-addto-links {
            margin: 0;
        }

        .towishlist,
        .tocompare {
            span {
                display: none;
            }
        }

        .towishlist {
            @include icon('wishlist');
        }

        .tocompare {
            @include icon('compare');
        }
    }

    .product.info.detailed {
        @include lib-css(margin-top, $indent__m);

        .additional-attributes-wrapper,
        .additional-attributes.table {
            margin-bottom: 0;
        }

        .data.title .switch {
            color: $primary__color;
        }

        .data-switch-title {
            font-size: $font-size__l;
            font-weight: $font-weight__semibold;
            height: $accordion-control__height;
            line-height: $accordion-control__height;
            margin: 0;

            @include min-screen($screen__m) {
                font-size: $h4__font-size;
                line-height: $accordion-control__height / 2;
            }
        }
    }

    .data.item.content.additional {
        flex-wrap: wrap;

        @include min-screen($screen__m) {
            display: flex;
            flex-wrap: nowrap;
            gap: $indent__s;
        }

        > * {
            width: 100%;

            @include min-screen($screen__m) {
                width: 50%;
            }

            @include compatibility-list;
        }
    }

    .additional-attributes-wrapper {
        height: 100%;
        padding: $indent__l;
        border: 1px solid $color-gray82;
        border-radius: $indent__xs;

        .products-attribute-heading {
            font-weight: $font-weight__bold;
            margin-top: 0;
        }

        .col.label {
            // Overrides rules with higher specificity
            padding: $indent__xs !important;
        }

        .col.data {
            // Overrides rules with higher specificity
            padding: $indent__xs $indent__xs $indent__xs $indent__s !important;
        }

        .col.label,
        .col.data {
            color: $color-prussian-blue;
            vertical-align: middle;
        }

        .col.label {
            font-weight: $font-weight__regular;
        }

        .additional-attributes {
            tr:not(:last-child) {
                border-bottom: 1px solid $color-gray82;
            }

            .col.data {
                border-left: 1px solid $color-gray82
            }
        }
    }
}

.product.info.detailed {
    .data.title {
        .data-switch-title {
            &:after {
                content: '+';
                float: right;

                @include min-screen($screen__m) {
                    content: '';
                }
            }
        }

        &[aria-expanded="true"] .data-switch-title {
            &:after {
                content: '-';
                float: right;

                @include min-screen($screen__m) {
                    content: '';
                }
            }
        }
    }
}
