.plist {
    @include lib-list-reset-styles;

    margin-left: rem(-$indent__s);

    .post-item {
        @include flex-grid;

        margin-left: 0;

        .post-block {
            box-shadow: 0 0 rem(3px) $color-gray68;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }

        .post-image {
            transition: all 400ms;

            &:hover {
                transform: scale(1.1);
                opacity: 0.5;
            }
        }

        .post-meta {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: rem($indent__s);
        }

        .post-readmore {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;

            a {
                text-align: center;
                text-transform: uppercase;
                width: 100%;
            }
        }

        .post-title h4 {
            font-size: $font-size__l;
        }
    }
}
