.column.main .block-wishlist .block-content {
    .product-items {
        margin-bottom: $indent__xs;
        margin-top: $indent__base;

        .product-item {
            padding-bottom: $indent__base;

            .product-item-info .product-item-actions .action.tocart {
                float: none;
            }

            .product-item-name {
                margin-right: 0;
            }
        }
    }
}

.form-wishlist-items .actions-toolbar {
    margin-top: $indent__base;

    .action {
        @include button-secondary;
        @include button-secondary-layout;
        @include max-screen($screen__m) {
            margin-bottom: $indent__s;
            width: 100%;

            &:not(:first-child) {
                margin-left: 0;
            }
        }

        margin-bottom: auto;
    }

    .primary {
        display: flex;
        float: none;

        @include max-screen($screen__m) {
            flex-direction: column;
            flex-wrap: wrap;
        }
    }

    .secondary {
        display: none;
    }
}

.header.panel > .header.links > .link.wishlist {
    @include max-screen($screen__l + 100px) {
        display: none;
    }
}

.products-grid.wishlist {
    margin-right: 0;

    .product-items {
        display: grid;
        grid-gap: $indent__base;
        grid-template-columns: repeat(auto-fill, minmax($indent__xl * 6, 1fr)); // 240px minimum, 3 items per row
        justify-content: start;

        .page-layout-2columns-left & {
            grid-template-columns: repeat(auto-fill, minmax(215px, 1fr)); // 4 items per row
        }

        .product-item {
            border-bottom-color: $color-gray82;
            border-top-color: $color-gray82;

            // also overrides default widget styling
            margin-bottom: 0 !important;
            margin-left: 0 !important;
            padding: $indent__s !important;
            width: 100% !important;

            .actions-primary,
            .actions-secondary,
            .action.primary {
                text-align: center;
                width: 100%;
            }

            .actions-secondary {
                @include lib-css(margin-top, $indent__s);
            }

            .box-tocart {
                float: none;
                margin-right: 0;

                @include max-screen($screen__m) {
                    margin-bottom: $indent__s;
                }
            }

            .price-box {
                margin-top: auto;
                min-height: $indent__xl * 1.1;

                .price {
                    @include lib-font-size(16);
                }
            }

            .price-box,
            .product-item-name {
                margin-left: 0;
                text-align: center;
            }

            .product-item-actions {
                .action {
                    float: none;
                    margin: 0;
                }

                .action.delete {
                    flex: 55%;
                    margin-left: $indent__s;
                }

                .action.edit {
                    flex: 35%;
                }
            }

            .product-item-inner {
                margin-top: auto;
            }

            .product-item-name {
                margin-bottom: $indent__s;

                a {
                    @include lib-font-size($font-size__l);
                }
            }

            .product-item-photo {
                margin: 0;
            }
        }

        .product-image-container {
            max-width: 100%;
        }

        .product-image-wrapper {
            height: auto;
            padding-bottom: 0 !important;
        }
    }
}

.wishlist-toolbar .pager {
    margin: 0;
}
