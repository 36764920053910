$widget-button__background-color:   #4db2ec;
$video-aspect-ratio:                16/9; // 16/9 = 16:9 Ratio

.youtube-embed {
    background-color: $color-prussian-blue;
    display: flex;
    flex-direction: column;
    padding: $indent__l;
    width: 100%;

    @include min-screen($screen__m) {
        flex-direction: row;
    }

    .youtube-container,
    .block-content {
        padding: $indent__s;
    }

    .block-content {
        color: $color-white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 100%;

        @include min-screen($screen__m) {
            width: 50%;
        }

        .link-btn {
            background-color: $widget-button__background-color;
            color: $color-white;
            display: inline-block;
            line-height: 1;
            margin-top: $indent__s;
            padding: $indent__s (1.2 * $indent__s);
            text-transform: uppercase;
            width: 100%;
        }

        .text {
            font-size: $font-size__base;

            @include min-screen($screen__m) {
                font-size: $font-size__l;
            }
        }
    }

    .youtube-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        @include min-screen($screen__m) {
            width: 50%;
        }

        lite-youtube {
            border: 2px solid $color-gray92;
            max-width: 100%;

            @include max-screen($screen__m) {
                height: 100vw / $video-aspect-ratio;
                width: 100vw;
            }
        }
    }
}

.youtube-embed.youtube-embed--light .block-content {
    color: $color-prussian-blue;
}
