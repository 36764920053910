@mixin compatibility-list {
    .block.category-selector {
        @include min-screen($screen__m) {
            margin-top: $indent__l;
        }
    }

    .category-selector__heading {
        font-weight: $font-weight__bold;
    }

    .category-container {
        &:not(:last-child) {
            margin-bottom: $indent__s;
        }

        &__title {
            cursor: pointer;
            font-weight: $font-weight__bold;
            margin-bottom: $indent__s;
        }

        &__name,
        &__content {
            padding-left: $compatibility-content__indentation;

            li {
                list-style: none;
            }
        }

        &__content li {
            text-decoration: underline;
        }

        ul {
            display: inline-block;
            margin: 0;
            padding-left: $indent__base;
            width: calc(100% - $compatibility-icon__size);
        }

        .category-container__title {
            align-items: center;
            display: flex !important;

            li {
                margin: 0;
            }

            .trigger {
                @include lib-icon-font(
                    $icon-expand,
                    $_icon-font-size: $compatibility-icon__size,
                    $_icon-font-line-height: $compatibility-icon__size,
                    $_icon-font-color: $compatibility-icon__color
                );
            }

            .trigger:before {
                background: $color-gray78;
                border: 1px solid $color-gray34;
            }
        }

        &.active .category-container__title .trigger {
            @include lib-icon-font(
                $icon-collapse,
                $_icon-font-size: $compatibility-icon__size,
                $_icon-font-line-height: $compatibility-icon__size,
                $_icon-font-color: $color-prussian-blue
            );
        }
    }
}
