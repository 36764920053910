.page-product-downloadable {
    .product-options-bottom,
    .product-options-wrapper {
        float: none;
        width: 100%;
    }

    .product-options-wrapper {
        margin-bottom: $indent__base;
    }
}
