//
//  Theme variables
//  _____________________________________________

//
//  Fonts
//  ---------------------------------------------

// Font Families
$font-family-name__muli : 'Muli';
$font-family__sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family__base      : $font-family-name__muli, $font-family__sans-serif;

// Sizes
$h1__font-size: 2rem; // 20px

$h1__font-size-desktop: 36px;
$h1__font-size-mobile: 24px;

$recaptcha_top_spacing: 30px;
$recaptcha_left_spacing: 25.5%;

// Font Weights
$font-weight__heavier: 500;

$h1__font-weight: $font-weight__heavier;
$h2__font-weight: $font-weight__heavier;
$h3__font-weight: $font-weight__heavier;
$h4__font-weight: $font-weight__heavier;
$h5__font-weight: $font-weight__heavier;


//
//  Color variables
//  _____________________________________________

$color-alabaster    : #fcfcfc;
$color-prussian-blue: #003756;
$color-blue-whale   : #002b44;
$color-picton-blue  : #4db2ec;
$color-suva-grey    : #8f8f8f;
$color-white-ice    : #dff9eb;
$color-polar        : #dff3f9;
$color-shamrock     : #34df7f;
$color-solitude     : #e7f6ff;

$star-review-colour : #ed9d00;

// Color Nesting
$primary__color     : $color-prussian-blue;
$secondary__color   : $color-blue-whale;

$header-icons-color: $color-alabaster;
$header-icons-color-hover: $color-suva-grey;


// Rem Baseline
$rem-baseline: 10px;

//
//  Buttons
//  ---------------------------------------------

// Primary Button
$button-primary__line-height                  : 40px;
$button-primary__margin                       : false;
$button-primary__padding                      : 0 1rem;

$button-primary__background                   : $color-prussian-blue;
$button-primary__border                       : 1px solid $color-prussian-blue;

$button-primary__hover__background            : $color-blue-whale;
$button-primary__hover__border                : 1px solid $color-blue-whale;

//
//  Links
//  ---------------------------------------------

$link__color                    : $color-prussian-blue;
$link__text-decoration          : none;
$_link-text-decoration-visited  : underline;

$link__visited__color           : $color-prussian-blue;
$link__visited__text-decoration : underline;

$link__hover__color             : $color-prussian-blue;
$link__hover__text-decoration   : underline;

$link__active__color            : $color-prussian-blue;
$link__active__text-decoration  : underline;;
