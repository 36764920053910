.easycatalogimg-grid {
    @include lib-vendor-prefix-display;
    @include lib-vendor-prefix-flex-wrap;
    @include lib-css(margin-left, 0 - $indent__s);

    list-style: none;
    padding: 0;

    @for $col from 2 through 6 {
        &.easycatalogimg-cols-#{$col} .item {
            @include lib-css(width, calc(#{100% / ($col / 2)} - #{$indent__s}));
            @include lib-css(margin-left, $indent__s);

            @include min-screen($screen__m) {
                @include lib-css(width, calc(#{100% / $col} - #{$indent__s}));
            }
        }
    }

    .item {
        @include lib-css(background-color, $secondary__color);
        @include lib_css(margin-bottom, $indent__s);
        @include lib-css(padding-bottom, $indent__s * 2);

        .category-name {
            @include lib-css(background-color, $primary__color);
            @include lib-css(border-bottom, 1px solid $color-white);
            @include lib-font-size(16);
            @include lib-vendor-prefix-display;
            @include lib-vendor-prefix-flex-direction;

            height: 100%;
            text-align: center;

            @include min-screen($screen__m) {
                @include lib-font-size($font-size__l);
            }

            a {
                @include lib-css(color, $color-white);
                @include lib-css(padding, $indent__s * 1.5);

                height: 100%;
                text-decoration: none;

                &:hover {
                    @include lib-css(text-decoration, $link__hover__text-decoration);
                }

                .category-name-item {
                    display: block;

                    &:first-child {
                        margin: $indent__s 0;
                    }

                    &:not(:first-child) {
                        @include lib-font-size($font-size__base);
                    }
                }
            }
        }
    }
}
