.read-more-link {
    @include lib-button-icon(
            $_icon-font-content     : $icon-down,
            $_icon-font-position    : $read-more-link__icon-position,
            $_icon-font-size        : $read-more-link__icon-size,
            $_icon-font-line-height : $read-more-link__icon-line-height
    );

    cursor: pointer;

    &.open {
        @include lib-button-icon(
                $_icon-font-content     : $icon-up,
                $_icon-font-position    : $read-more-link__icon-position,
                $_icon-font-size        : $read-more-link__icon-size,
                $_icon-font-line-height : $read-more-link__icon-line-height
        );
    }
}
