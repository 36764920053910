.customer-wrapper {
    @include lib-css(margin-top, $indent__s);
    @include lib-dropdown(
        $_toggle-selector: '.action.customer',
        $_options-selector: '.block-customer',
        $_dropdown-toggle-icon-content: $icon-down,
        $_dropdown-toggle-active-icon-content: $icon-up,
        $_dropdown-list-item-padding: false,
        $_dropdown-list-item-hover: false,
        $_dropdown-list-pointer-position: right,
        $_icon-font-size: 19px,
        $_icon-font-line-height: 19px
    );
    @include separator;

    display: none;
    float: right;
    position: relative;

    @include min-screen($screen__m) {
        display: inline-block;
        margin-top: 0;
    }

    .action.customer {
        @include lib-button-reset();
    }

    .block-customer {
        right: 0;
        top: auto;
        z-index: 101;

        ul.links {
            list-style: none;
            margin-bottom: 0;
            padding: 0;
            white-space: nowrap;

            li {
                @include lib-css(border-bottom, $dropdown-list__border);

                &:last-of-type {
                    border-bottom: 0;
                }

                a {
                    @include lib-css(color, $link__color);
                    @include lib-css(padding, $indent__s);

                    display: block;

                    &:hover {
                        @include lib-css(color, $link__color);
                    }
                }
            }
        }
    }
}
