.related-add-form,
.product-add-form,
.product-options-bottom {
    .box-tocart {
        &,
        .field.qty {
            display: inline-block;
            margin: 0;
        }

        .field.qty {
            .label {
                display: none;
            }

            .control {
                border: $form-element-input__border;
                border-radius: $form-element-input__border-radius;
                max-width: 100%;
                position: relative;
                user-select: none;
                width: rem(100px);

                .product-info-main & {
                    margin-bottom: $indent__s;
                }

                .input-text.qty {
                    border: none;
                    padding: 0;
                    height: initial;
                    line-height: rem(50px);
                    text-align: center;
                    width: 100%;
                }

                .mage-error[generated] {
                    float: left;
                    margin-bottom: $indent__s;
                    width: 7 * $indent__xl;

                    @include min-screen($screen__xs) {
                        width: 8 * $indent__xl;
                    }
                }

                .quantity-control {
                    @include lib-css(padding, 0 $indent__s);
                    cursor: pointer;
                    display: inline-block;
                    line-height: rem(50px);
                    margin: 0;
                    position: absolute;

                    &.qty-decrease {
                        left: 0;
                    }

                    &.qty-increase {
                        right: 0;
                    }
                }
            }
        }

        .actions {
            display: inline-block;
            padding-top: 0;
            vertical-align: top;
        }
    }
}
