.part-finder-wrapper {
    background: center no-repeat;
    background-size: cover;

    @include min-screen($screen__m) {
        padding: $part-finder-wrapper__padding;
    }
}

.part-finder {
    @include radio-button;

    background-color: $part-finder__primary-color;
    color: $part-finder__text-color;

    @include min-screen($screen__m) {
        background-color: rgba($part-finder__primary-color, 0.9);
    }

    > * {
        padding: 1em;
    }

    fieldset > *:not(.action.primary) {
        margin-bottom: 0.5em;
    }

    .part-finder__fieldset-wrapper {
        @include min-screen($screen__m) {
            display: flex;
            flex-wrap: wrap;

            > * {
                padding: $indent__s;
            }

            > :first-child {
                width: 33.33%;
            }

            > :last-child {
                width: 66.66%;
            }
        }

        .part-finder__model-fieldset {
            @include min-screen($screen__m) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
    }

    .part-finder__manufacturer-fieldset {
        margin-bottom: $indent__s;

        @include min-screen($screen__m) {
            margin-bottom: 0;
        }


        .manufacturer-label {
            width: 100%;
        }

        .manufacturer-button {
            display: inline-block;
            width: calc(50% - 0.25em);

            @include min-screen($screen__m) {
                &:first-child {
                    padding-right: $indent__xs;
                }

                &:last-child {
                    padding-left: $indent__xs;
                }
            }

            span {
                font-size: $font-size__base;
                line-height: 4rem; // Same as primary button
                text-align: center;
                width: 100%;
            }
        }
    }

    .part-finder__model {
        color: $color-black;

        @include min-screen ($screen__m) {
            width: calc(50% - #{$indent__s});
        }
    }

    .part-finder__title {
        border-bottom: 1px solid $part-finder__secondary-color;

        h2 {
            margin: 0;
        }
    }

    .action.primary {
        background-color: $part-finder__secondary-color;
        text-transform: none;
        width: 100%;

        @include min-screen ($screen__m) {
            width: calc(50% - #{$indent__s});
        }
    }

    .manufacturer-button,
    .part-finder__model,
    .action.primary {
        height: $part-finder-input__height;
    }

    .manufacturer-label,
    .model-label {
        display: block;

        @include min-screen($screen__m) {
            font-weight: $font-weight__bold;
            font-size: 1.1em;
        }
    }

    .model-label {
        @include min-screen($screen__m) {
            width: 100%;
        }
    }
}
