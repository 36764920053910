.modals-wrapper .modal-popup._show {
    .modal-inner-wrap {
        left: 0;
        margin-top: $indent__base;

        .action.primary {
            line-height: inherit;
            padding: $button__padding;
        }
    }
}
