@mixin icon($icon) {
    background-color: $page__background-color;
    background-image: url('../images/#{$icon}.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    box-shadow: none;
    height: $indent__base;
    margin-right: $indent__s;
    width: $indent__base;

    span {
        display: none;
    }

    &:hover {
        background-color: $page__background-color;
        background-image: url('../images/#{$icon}.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        border: none;
        box-shadow: none;
    }
}
