body.page-with-filter {
    &.filter-active {
        .page-wrapper {
            visibility: hidden;

            @include min-screen($screen__m) {
                visibility: initial;
            }

            .block-static-block {
                display: none;
            }
        }
    }
}

.sidebar .block.filter {
    &.active {
        @include max-screen($screen__m) {
            visibility: visible;
        }

        // close button
        .filter-title {
            margin: $indent__s;
            position: fixed;
            right: 0;
            top: 0;

            @include min-screen($screen__m) {
                margin: 0;
                position: initial;
            }

            strong {
                @include lib-icon-font(
                        $icon-remove,
                        $_icon-font-color: $color-prussian-blue,
                        $_icon-font-size: 32px,
                        $_icon-font-line-height: initial,
                        $_icon-font-position: after
                );

                border: 0;
                color: transparent;
                padding: 0;
            }
        }

        .filter-subtitle {
            @extend h4;

            display: block;
            left: 0;
            margin: 0;
            padding-left: $indent__base;
            position: fixed;
            top: 15px;

            @include min-screen($screen__m) {
                display: none;
            }
        }

        .filter-options {
            &-item {
                padding: $indent__s  $indent__base;
            }
        }
    }



    // layered navigation title & mobile button
    .filter-title {
        @include max-screen($screen__m) {
            margin: 0;
        }
    }

    // mobile filter menu title
    .filter-subtitle {
        display: none;
    }

    .filter-content {
        @include max-screen($screen__m) {
            bottom: 0;
            display: block;
            left: 0;
            overflow-x: hidden;
            overflow-y: scroll;
            position: fixed;
            right: 0;
            top: 50px;
        }

        .filter-current {
            border: solid $color-gray82;
            border-width: 1px 0;
            padding: $indent__s $indent__base;

            .action.remove {
                &:before {
                    content: '-';
                    padding-right: 1px;
                }
            }

            @include min-screen($screen__m) {
                border: none;
                padding: 0;
            }

            &-subtitle {
                @include max-screen($screen__m) {
                    margin: 0;
                }

                &:before {
                    float: right;

                    @include min-screen($screen__m) {
                        display: none;
                    }
                }

                &:after {
                    content: ' (' attr(data-count) ')';
                }
            }
        }

        .filter-actions {
            @include max-screen($screen__m) {
                padding: $indent__s $indent__base;
                margin-bottom: 0;
            }
        }
    }

    .filter-options {
        &-item {
            padding-top: $indent__s;
        }

        &-title {
            font-size: $font-size__l;
            font-weight: $font-weight__bold;
            word-break: normal;

            &:after {
                float: right;
            }
        }

        &-content {
            margin: 0;
        }
    }
}
