.nav-sections {
    @include lib-css(background-color, $color-alabaster);
    margin-bottom: 0;

    @include min-screen($screen__m) {
        @include lib-css(background-color, $secondary__color);
    }

    &-item-title:not(.active) a {
        @include max-screen($screen__m) {
            color: $color-alabaster;
        }
    }

    .navigation {
        @include lib-css(background-color, $color-alabaster);

        @include min-screen($screen__m) {
            @include lib-css(background-color, $secondary__color);
        }

        .item-icon {
            @include lib-css(color, $secondary__color);

            @include min-screen($screen__m) {
                @include lib-css(color, $color-alabaster);
            }
        }
    }
}
