@mixin button-secondary {
    @include lib-button-primary;
    @include lib-button-revert-secondary-color(
        $_button-border: $button__background,
        $_button-color: $color-black,
        $_button-border-hover: $button__hover__color,
        $_button-border-active: $button__active__color
    );
    @include lib-css(border-radius, $button__border-radius);
    @include lib-css(margin-bottom, $indent__s);
    @include lib-link-as-button;

    text-transform: uppercase;
    transition: background-color 200ms;
}

@mixin button-secondary-layout {
    &:not(:first-child) {
        @include lib-css(margin-left, $indent__xs);
    }

    float: right;
    text-align: center;
    width: calc(#{(100% / 3) * 2} - #{$indent__xs});
}
